import { render, staticRenderFns } from "./companies-staffed-table.vue?vue&type=template&id=38d6eba8&scoped=true"
import script from "./companies-staffed-table.vue?vue&type=script&lang=js"
export * from "./companies-staffed-table.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d6eba8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TableFilters: require('/codebuild/output/src2285020857/src/web/components/table-filters.vue').default,TableActions: require('/codebuild/output/src2285020857/src/web/components/table-actions.vue').default})
